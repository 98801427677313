<template>
  <div class="signup-page">
    <div class="outer">
      <div class="text-center">
        <img :src="require('@/assets/images/global/Logo.png')" alt="" />
      </div>
      <div class="my-2 text-center">
        <select v-model="isDebug" style="color: red">
          <option :value="false"></option>
          <option :value="true">Debug Mode</option>
        </select>
      </div>
      <div class="cover">
        <span class="black--text font-26">Hi There!</span>
        <p class="black-grey--text font-18">
          We’re happy to have you here again!
        </p>
        <v-form @submit.prevent="submitHandler" v-model="valid">
          <div class="mt-5">
            <label class="black-grey--text font-12 font-500"
              >E-mail address</label
            >
            <v-text-field
              v-model="user.email"
              type="email"
              :rules="emailRules"
              placeholder="E-mail"
              required
              hide-details="true"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="mt-5">
            <label class="black-grey--text font-12 font-500">Password</label>
            <v-text-field
              v-model="user.password"
              type="password"
              :rules="[(v) => !!v || 'Password is required']"
              placeholder="Password"
              required
              hide-details="true"
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="text-right my-5">
            <v-btn
              block
              color="secondary"
              elevation="0"
              type="submit"
              :dark="valid"
            >
              Login
            </v-btn>
          </div>
        </v-form>
      </div>
      <div class="my-2 text-center">
        <router-link to="/forget-password" tag="span">
          <span class="darkblue--text cursor-pointer">Forgot password?</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    user: {},
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ]
  }),
  computed: {
    isDebug: {
      get() {
        return localStorage.getItem("isDebug") || "false";
      },
      set(value) {
        localStorage.setItem("isDebug", value);
        this.$router.go();
      }
    }
  },
  methods: {
    async submitHandler() {
      const res = await this.$axios.post("/auth/general", { ...this.user });
      if (res.data && res.data.success) {
        this.$store.dispatch("setUser", res.data);
        this.$store.dispatch("showSnack", {
          text: "logged successfully",
          color: "success"
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.signup-page {
  background: #f2f2f2;
  width: 100%;
  height: 100vh;
  position: relative;
  .outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .cover {
      margin: 0px auto;
      width: 80%;
      max-width: 450px;
      background: white;
      border-radius: 8px;
      box-shadow: 0px 0px 5px #eaedf396;
      padding: 35px;
      margin-top: 80px;
    }
  }
}
</style>
